import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Table Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/table/"
    metaDescription="Leverage Uizard's table UI component templates to create tables that you can combine with other additional sections to create powerful screens."
    description="
    h2:A set of tables for your business projects
    <br/>
    Drag-and-drop table component template to create interactive, user-friendly data tables for your projects.
    <br/>
    h3:Drag-and-drop, then customize
    <br/>
    Leverage Uizard's table component template to create interactive, user-friendly data tables that you can combine with other additional sections to create powerful screens. 
    <br/>
    Just drag-and-drop the table component template, and then customize all the settings and many other elements of the selected template to fit your use case.
    <br/>
    h3:It takes just a few clicks to set up a full design with tables
    <br/>
    With Uizard, it only takes a few clicks to design apps or websites and add table component template that you can fill with any content you want to be displayed in the table. 
    <br/>
    What makes Uizard's table component template stand out is that you can customize them thoroughly. Change the content, the arrangement, or the style — it's all up to you!
    "
    pages={["Data table with pagination", "Data table text", "Big data table"]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing tables"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing tables"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing tables"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing tables"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing tables"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/table/table_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/table/table_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/table/table_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/table/table_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/table/table_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
